import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import IndexSPA from "../../components/IndexSPA"

const Index = (props) => {
   return (
      <>
        <Helmet
          title="主持人評論與討論回應 - 20 X 20 - 社宅公共藝術的 N+1 種想像 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "社會住宅的公共藝術即將開始啟動，在各類藝術計畫啟動之前，我們期許能擴大各種想像以及討論，擾動藝文工作者對於參與社宅公共藝術的認知與期待，鋪陳日後與藝術家在實際實踐場域的互動與參與，打開社宅公共藝術的N+1種想像！",
            },
            {
              property: `og:title`,
              content: "主持人評論與討論回應 - 20 X 20 - 社宅公共藝術的 N+1 種想像 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "社會住宅的公共藝術即將開始啟動，在各類藝術計畫啟動之前，我們期許能擴大各種想像以及討論，擾動藝文工作者對於參與社宅公共藝術的認知與期待，鋪陳日後與藝術家在實際實踐場域的互動與參與，打開社宅公共藝術的N+1種想像！",
            },
            {
              property: `og:image`,
              content: 'https://online.home-to-all.com' + require('../../images/blog/post_1_6.jpg')
            }
          ]}
        >
        </Helmet>
         <IndexSPA />
      </>
   )
 };
 
 
 export default Index;